.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

body {
  background-color:#343541;
  color: #ECECF1;
}

* {
  font-family: courier;
}

.TextToImage {
  align-items: center;
  padding: 2rem;
}
/* Selectables */

.SelectableComponent {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
}

.SelectableChild {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0e0e0;
  border-radius: 4px;
  padding: 2rem;
}

.selected {
  border: 6px solid yellow;
}

.expanding-textarea {
  width: 90%;
  overflow: 'hidden';
  resize: 'none';
}

@media (min-width: 768px) {
  .expanding-textarea {
      width: 50%;
  }
}


/* Home screen minigames */


.minigame-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.minigame-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #434654;
}

.minigame-title {
  margin-top: 1rem;
  font-size: 1.5rem;
}

.minigame-image-container {
  position: relative; 
  width: 15rem;       
  height: 15rem;
  overflow: hidden;   
  border-radius: 0.5rem;
}

.minigame-image {
  width: 100%;       
  height: 100%;
  object-fit: cover;
}

.minigame-image.beta {
  filter: grayscale(100%);
}

.construction-overlay {
  position: absolute;
  filter: grayscale(50%);
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
  background: url('./Assets/Images/UnderConstruction.png') no-repeat center center;
  background-size: contain;
}

/* Scribble */

.square-container {
  border: 5px solid black;
  width: 512px;
  max-width: 100vw;
  position: relative;
  overflow: hidden;
}

/* This ensures the container is always a square */
.square-container::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.square-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}